import React from "react"
import { Link } from "gatsby"
import HeroImage from "../../../contents/Home/HeroImage"
import SvgDevices from "../../../contents/Home/SvgDevices"
import SvgDeadPixelRepair from "../../../contents/Home/SvgDeadPixelRepair"
import SvgStuckPixelRepair from "../../../contents/Home/SvgStuckPixelRepair"
import SEO from "../../../components/Seo"
const HomePage = () => {
  return (
    <>
      <SEO
        title="Verificação de pixel"
        lang="pt"
        description="Teste para pixels mortos, pixels presos, reparar pixels mortos e pixels presos em seus monitores, monitores, tablets, computadores e TVs."
        keywords="teste de pixels morto, teste de pixel preso, reparação de pixels preso, reparação de pixels morta, teste de pixels preso, o que é pixel morto, o que é pixelado, o que é pixelado preso, o que é pixel preso, pixel morto Diferença de pixel, teste de pixels, teste de pixel, pixel morto, teste de pixel, pixel preso, reparação de pixel online"
      />
      <h1 className="pb-0 mb-0 text-center">
        Verifique se há pixels <span className="text-indigo-600">mortos</span>{" "}
        ou <span className="text-red-500">presos!</span>
      </h1>

      <HeroImage className="max-w-full px-3 md:px-10" />

      <p className="lead">
        Você pode testar pixels mortos ou pixels presos em seus telefones
        celulares, tablets, televisores e computadores através do navegador e
        usar a ferramenta gratuita de reparo de pixels sem qualquer instalação.
      </p>
      <h2>Teste de pixel morto ou preso</h2>
      <p>
        Seja tablet, telefone ou monitor ... Quando você compra qualquer
        dispositivo com uma tela, você precisa fazer o teste de pixel morto e o
        teste de pixel preso após a instalação.
      </p>
      <p>
        Quanto mais cedo você perceber um potencial defeito no produto que você
        comprou, Quanto mais fácil será processar o retorno e a troca.Além
        disso, isso processo não demora muito tempo, você pode facilmente testar
        pixels mortos e pixels presos em alguns minutos.
      </p>
      <SvgDevices className="max-w-full px-3 md:px-10" />

      <p>
        Você pode iniciar o teste indo para a página correspondente clicando no
        botão <code>Test Dead Pixel</code> abaixo.Na página que abre, há
        informações detalhadas sobre o pixel morto e o teste de pixel preso, bem
        como a ferramenta de teste.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/verifique-o-pixel-morto"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Test Dead Pixel</span>
        </Link>
      </div>
      <h2>Como reparar pixel morto, corrigir problema de pixel morto?</h2>
      <p>
        Um pixel morto significa que um pixel na tela chegou ao final de sua
        vida.Como não toma energia, é geralmente preto de cor.Não há solução de
        software para corrigir o problema de pixel morto, geralmente o painel e
        a substituição da tela são necessários para isso.Empurrando suavemente
        pode funcionar se houver pixels mortos em telas LCD causadas pela
        emissão de cristais líquidos.
      </p>
      <SvgDeadPixelRepair className="max-w-full px-3 md:px-10" />

      <p>
        Na página, você abrirá clicando no botão{" "}
        <code>Reparar Morto Pixels</code> abaixo, é explicado passo a passo como
        remover seus pixels mortos, pressionando suavemente.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/dead-pixel-repair"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Reparar Morto Pixels</span>
        </Link>
      </div>
      <h2>Como reparar o pixel preso, corrigir problema de pixel preso?</h2>
      <p>
        As cores que não mudam em sua tela, especialmente se sua cor não é negra
        ou branca, nem sempre significa que eles são pixels mortos.Pixels que
        permanecem em uma cor por um longo tempo podem ficar presos em cores,
        como vermelho, verde, azul.
      </p>
      <SvgStuckPixelRepair className="max-w-full px-3 md:px-10" />
      <p>
        Existe uma ferramenta de reparo gratuita para pixels presos na página
        que se abre quando você clica no botão{" "}
        <code className="capitalize">Reparar pixels preso</code> abaixo.Além de
        informar sobre o uso da ferramenta de reparo de pixels presas, você
        também pode encontrar informações sobre o que o pixel preso é e a
        diferença entre pixel presa e pixel morto na página de reparo de pixels
        presas.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/correcao-de-pixel-travado"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">Reparar pixels preso</span>
        </Link>
      </div>
    </>
  )
}
export default HomePage
